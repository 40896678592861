<template>
  <div class="d-flex align-center">
    <v-menu v-model="openMenu" offset-y left>
      <template #activator="{ on, attrs }">
        <icon-button v-if="media.isMobile" class="ms-3" v-bind="attrs" v-on="on">
          <v-icon>
            mdi-dots-horizontal
          </v-icon>
        </icon-button>
        <v-btn v-else elevation="0" color="primary" large v-bind="attrs" v-on="on">
          {{ $t('button.add') }}
          <v-icon class="ms-2">mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="[$emit('add-type'), (openMenu = false)]">
          <v-icon color="black" class="me-2">mdi-plus</v-icon>
          {{ $t('button.add_type') }}
        </v-list-item>
        <v-list-item @click="[$emit('add-category'), (openMenu = false)]">
          <v-icon color="black" class="me-2">mdi-plus</v-icon>
          {{ $t('button.add_category') }}
        </v-list-item>
        <v-list-item @click="[$emit('add-request'), (openMenu = false)]">
          <v-icon color="black" class="me-2">mdi-plus</v-icon>
          {{ $t('button.add_request') }}
        </v-list-item>
        <upload-terms v-if="media.isMobile" />
      </v-list>
    </v-menu>
    <div v-if="!media.isMobile" class="d-flex requests-actions">
      <upload-terms />
    </div>
  </div>
</template>

<script>
// Components
import IconButton from '@/components/IconButton.vue';
import UploadTerms from './UploadTerms.vue';

export default {
  name: 'RequestsMenu',

  components: { IconButton, UploadTerms },

  inject: ['media'],

  data() {
    return {
      openMenu: false,
    };
  },
};
</script>
