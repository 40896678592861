<template>
  <form-builder
    ref="formBuilder"
    v-model="valueModel"
    enable-cache
    remove-label-suffix
    class="requests-filters"
    :schema="$options.schema"
    :initial-data="value"
  >
  </form-builder>
</template>

<script>
// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema } from '@/schemas/requestsFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'RequestsFilters',

  components: { FormBuilder },

  props: {
    value: { type: Object, default: () => ({}) },
    isArchive: { type: Boolean, default: false },
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
  },

  methods: {
    reset() {
      this.$refs.formBuilder.reset();
    },
  },

  schema,
};
</script>

<style lang="scss">
.requests-filters {
  .v-form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    span {
      flex-grow: 1;
      margin-left: 8px;
      margin-right: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
