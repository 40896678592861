<template>
  <v-dialog v-model="dialogTerms" width="560">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-if="media.isMobile" v-bind="attrs" v-on="on">
        <v-icon class="me-2" color="black">mdi-pencil</v-icon>
        <span>{{ $t('button.edit_terms') }}</span>
      </v-list-item>
      <v-btn v-else v-bind="attrs" elevation="0" color="secondary" large class="primary--text ms-4" v-on="on">
        <span>{{ $t('button.edit_terms') }}</span>
        <v-icon class="ms-2">mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card content-class="base-modal__card">
      <v-card-title class="pb-8 px-4 pt-4 px-md-6 pt-md-6 align-start justify-space-between flex-nowrap">
        <h1 class="text-h6 text-md-h5 font-weight-bold">
          <span>{{ $t('requests.edit_terms') }}</span>
        </h1>

        <v-btn icon color="primary" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 px-md-6">
        <form-builder :schema="$options.shemaTerms" :initial-data="initialData" enable-cache @submit="submit">
          <template #footer>
            <v-btn large type="submit" color="primary" class="mt-7">{{ $t('button.save') }}</v-btn>
          </template>
        </form-builder>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Schema
import { shemaTerms } from '@/schemas/request.schema';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Services
import requestsService from '@/services/requests';
import notificationService from '@/services/notification';

export default {
  name: 'UploadTerms',

  components: { FormBuilder },

  inject: ['media'],

  props: {
    termFile: {
      type: [Object, File],
      default: null,
    },
  },

  data() {
    return {
      ticketTermsUrl: null,
      dialogTerms: false,
      initialData: createModelData(this.$options.shemaTerms),
    };
  },

  watch: {
    termFile: {
      handler(newFile) {
        this.file = newFile;
      },
      deep: true,
    },
  },

  mounted() {
    requestsService.getTerms().then(({ ticketTermsUrl }) => {
      this.ticketTermsUrl = ticketTermsUrl;
      this.initialData = { ticketTermsUrl };
    });
  },

  methods: {
    closeDialog() {
      this.dialogTerms = false;
      this.file = this.termFile;
    },

    async submit(data) {
      await requestsService.saveTerms(data).then(() => {
        this.closeDialog();
        notificationService.success(this.$t('requests.link_to_terms_added'), 2000);
      });
    },
  },

  shemaTerms,
};
</script>
