import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  CATEGORIES: 'categories',
  CATEGORY_TYPES: 'categoryTypes',
};

/** @type {import('./schema').default}  */
export const schema = [
  {
    type: schemaItemTypes.REQUEST_MANY_TYPES,
    label: 'label.type',
    prop: dependentProperties.CATEGORY_TYPES,
    payload: [
      {
        param: 'categories',
        from: [dependentProperties.CATEGORIES],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CATEGORIES,
      },
    ],
  },
  {
    type: schemaItemTypes.REQUEST_MANY_CATEGORY,
    label: 'label.category',
    prop: dependentProperties.CATEGORIES,
    payload: [
      {
        param: 'categoryTypes',
        from: [dependentProperties.CATEGORY_TYPES],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.CATEGORY_TYPES,
      },
    ],
  },
];

export { schema as default };
